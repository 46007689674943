import Head from 'next/head';
import '@/styles/globals.css'; // Ensure global styles are applied
import Script from 'next/script'

export default function MyApp({ Component, pageProps }) {
    return (
        <>
            {/* Load Mercado Pago's Checkout Pro V2 */}
            <Script
                src="https://sdk.mercadopago.com/js/v2"
                strategy="beforeInteractive"
            />

            {/* Head metadata */}
            <Head>
                <title>enem.app - Study Smarter</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta
                    name="description"
                    content="Corrija suas provas antigas e direcione seus estudos. O melhor jeito para estudar para o ENEM é treinar com provas antigas."
                />
                <meta
                    name="keywords"
                    content="ENEM, enem.app, correção de provas, desempenho ENEM, estudar para o ENEM, melhorar nota ENEM, simulado ENEM, questões resolvidas ENEM, gabarito ENEM, preparação para o ENEM, plataforma de estudos, revisão ENEM, redação ENEM, aprender com provas antigas, provas anteriores ENEM"
                />

                {/* Favicon Setup */}
                <link rel="icon" href="/favicon.ico" sizes="any" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16.png" />
                <link rel="apple-touch-icon" href="/favicons/apple-touch-icon.png" />
                <link rel="manifest" href="/favicons/site.webmanifest" />

                {/* Open Graph (Facebook, LinkedIn, WhatsApp) */}
                <meta property="og:title" content="enem.app - Study Smarter" />
                <meta
                    property="og:description"
                    content="Corrija suas provas anteriores e direcione seus estudos. O melhor jeito para estudar para o ENEM é treinar com provas passadas."
                />
                <meta property="og:image" content="https://www.enem.app/Gabi-profile-pic.png" />
                <meta property="og:url" content="https://www.enem.app" />
                <meta property="og:type" content="website" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="enem.app - Study Smarter" />
                <meta
                    name="twitter:description"
                    content="Corrija suas provas anteriores e direcione seus estudos. O melhor jeito para estudar para o ENEM é treinar com provas passadas."
                />
                <meta name="twitter:image" content="https://www.enem.app/Gabi-profile-pic.png" />

                {/* Canonical URL */}
                <link rel="canonical" href="https://www.enem.app" />

                {/* JSON‑LD Structured Data */}
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify([
                            {
                                "@context": "https://schema.org",
                                "@type": "Organization",
                                "name": "enem.app",
                                "url": "https://www.enem.app",
                                "logo": "https://www.enem.app/logo-square.png", // Mantenha essa imagem se preferir
                                "description": "Corrija suas provas antigas e direcione seus estudos. O melhor jeito para estudar para o ENEM é treinar com provas antigas."
                            },
                            {
                                "@context": "https://schema.org",
                                "@type": "WebSite",
                                "url": "https://www.enem.app",
                                "potentialAction": {
                                    "@type": "SearchAction",
                                    "target": "https://www.enem.app/search?q={search_term_string}",
                                    "query-input": "required name=search_term_string"
                                }
                            }
                        ])
                    }}
                />

                {/* Google Tag Manager Script */}
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-PFWNQ6V7');
                        `,
                    }}
                />
            </Head>

            {/* Render the page component */}
            <Component {...pageProps} />
        </>
    );
}
